import React, { Component,useState } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Foot,SliderA,Slider } from '../components/main'
import { cap,pw } from '../params'
import post from '../params/post'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import music from '../assets/music/denta.aac'
import AOS from 'aos';
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'
import '../style/arif.css'
import '../style/sty.scss'
import { gambar } from '../params'
import path from '../assets/img/arif/path1.svg'
import path3 from '../assets/img/adnan/path3.svg'
import arif from '../assets/img/arif/arif.webp'
import cincin from '../assets/img/arif/cincin.svg'
import logoig from '../assets/img/arif/logoig.svg'
import burung from '../assets/img/burung-white.svg'
import bg from '../assets/img/arif/modal.jpg'
import bg1 from '../assets/img/arif/bggrey.webp'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let id = "denta-fathan"
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi:1
        }
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 1000
        });
        var countDownDate = new Date("02/06/2021").getTime();

        // Update the count down every 1 second
        var x = setInterval(() => {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            this.setState({
                days: days, hours: hours, minutes: minutes, seconds: seconds
            })
        }, 1000);

    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();

        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }
    handleSubmit = () => {

        let local = localStorage.getItem('pesan')
        let err = []
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.alamat.current.value == "") {
            err.push('Alamat tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}${this.state.hadir?" sesi:"+this.state.sesi:''}", jumlahhadir: "", kepada: "denta-fathan", pesan:"${this.pesan.current.value}",alamat: "${this.alamat.current.value}"`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                                this.alamat.current.value = ''
                            }else{
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });

        } else {
            this.setState({ err: err, submitted: false })
        }


    }

    render() {

        let query = this.useQuery().get('name');
        query = query ? cap(query) : ''
        let { days, hours, minutes, seconds, hide, hadir, err, submitted ,sesi} = this.state
        let slider = []
        let slide = [
            "4M6A1293_edited.jpg",
"4M6A1367_edited.jpg",
"4M6A1374_edited.jpg",
"4M6A1375_edited.jpg",
"4M6A1382_edited.jpg",
"4M6A1401_edited.jpg",
"4M6A1411_edited.jpg",
"4M6A1415_edited.jpg",
"4M6A1473_edited.jpg",
"4M6A1482_edited.jpg",
        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        return (
            <>
                <Helmet>
                    <title>Undanganku - The Wedding of Denta & Fathan</title>
                    <meta name="title" content="The Wedding of Denta & Fathan" />
                    <meta name="description" content="undangan digital berbasis website untuk berbagai kebutuhan acara" />
                    <link rel="icon" href={logo}></link>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.2/css/swiper.min.css"></link>
                </Helmet>
                <div id='arif' style={{backgroundColor:'#171717'}}>

                    <Container fluid id='g3-header' className='relative pl-0 pr-0' style={{  backgroundImage: `url(${pw(id,"modal.jpg")})`}}>
                        <Item>
                            <Col xs={12} md={4} className='m-2 m-md-0 '>
                                <img className='img-fluid w-100 p-2' src={pw("denta-fathan","logo.png")} data-aos="fade-left" />
                            </Col>
                        </Item>
                        <Item>
                            {query ? (

                                <h2 className={`col-md-4 roboto-slab`}> Kepada : {query} </h2>

                            ) : false}
                        </Item>
                        <Row className='justify-content-center'>
                            <div onClick={() => { this.play() }}
                                className={`col-md-4 btn roboto-slab ${hide ? 'show' : 'hide'}`}
                                style={{borderColor:"rgb(245, 205, 79)",color:'rgb(245, 205, 79)'}}>
                                Open
                            </div>
                        </Row>
                        <Col className={`absolute path ${hide ? 'hide' : 'show'}`} xs={12}>
                            <img className='img-fluid w-100' src={path} />
                        </Col>
                    </Container>
                    {!hide ? (
                        <>

                            <Container fluid>
                            </Container>
                            <Container fluid className='bg-green pt-3 pb-3' id='top'>
                                <Item>
                                    <Col xs={4} md={2} className='p-3 p-md-5'>
                                        <img src={cincin} className='img-fluid w-100' data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8}>
                                        <p className='italic roboto-slab c-main' data-aos="fade-right">
                                            For the two of us, home isn't a place. It is a person. And we are finally home.
                                          </p>
                                    </Col>
                                </Item>
                            </Container>
                            <div className='bgnew' style={{
                                backgroundImage: `url(${pw(id,"modal.jpg")})`,
                            }}>

                                <Container id='pasangan' fluid style={{backgroundColor:'#171717'}}>
                                    <Item>
                                        <div className='p-3' id="pasangan">
                                            <Item>
                                                <Col xs={12} md={10} className='p-3 rounded' data-aos="fade-left">
                                                    <p className='c-white tinos'>
                                                        Bismillahirrahmanirrahim <br /><br />
                                                        Assalamualaikum warahmatullahi wabarakatuh<br />
                                                        Dengan memohon rahmat dan ridho Allah SWT, kami bermaksud menyelenggarakan resepsi pernikahan putra-putri kami,
                                                    </p>
                                                    <Row>
                                                        <Col xs={12} sm={5}>
                                                            <Item>
                                                                <h1 className='c-white lobster p-3'>
                                                                    The Bride
                                                            </h1>
                                                            </Item>
                                                            <Item>
                                                                <img src={pw(id,"ce.jpg")} style={{width:'150px',height:"150px",borderRadius:'10px'}}/>
                                                            </Item>

                                                            <Item>
                                                                <h1 className='c-main garamond p-3'>
                                                                    Deninta Nur Iwana
                                                            </h1>
                                                            </Item>
                                                            <div id="denta"> 
                                                            <div className='ortu'>
                                                                <Item>
                                                                    <p className=' s-bold tinos c-white' >Putri pertama dari:</p>
                                                                </Item>
                                                                <Item>
                                                                    <p className='tinos c-white'>
                                                                        Bpk. Iwan Hunaryat<br />
                                                                dan<br />
                                                                Ibu Nia Tri Agustini
                                                                </p>
                                                                </Item>
                                                            </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} sm={2} className="my-auto">
                                                            <h1 className='c-main lobster p-3 larger w-100 text-center '>
                                                                &
                                                        </h1>
                                                        </Col>
                                                        <Col xs={12} sm={5} className="px-0">
                                                            <Item>
                                                                <h1 className='c-white lobster p-3'>
                                                                    The Groom
                                                    </h1>
                                                            </Item>
                                                            <Item>
                                                                <img src={pw(id,"co.jpg")} style={{width:'150px',height:"150px",borderRadius:'10px'}}/>
                                                            </Item>

                                                            <Item>
                                                                <h1 className='c-main garamond p-3'>
                                                                    Hazred Umar Fathan

                                                        </h1>
                                                            </Item>
                                                            <div id="denta">
                                                            <div className='ortu'>
                                                                <Item>
                                                                    <p className='s-bold tinos c-white' style={{fontSize:'18px'}}>Putra ketiga dari:</p>
                                                                </Item>
                                                                <Item> 
                                                                    <p className='tinos ortu c-white'>

                                                                        Bpk. Zulmedia <br />
                                            dan<br />
                                            Ibu Sri Mulyati
                                        </p>
                                                                </Item>
                                                            </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </div>
                                    </Item>
                                </Container>
                                <Container>
                                </Container>
                                <Acara days={days}
                                    hours={hours}
                                    minutes={minutes}
                                    seconds={seconds}
                                />
                                <Transport />
                                <Attandance/>
                                <Story />
                                {/* <Galery/> */}
                                <Container className='bg-green mx' fluid id='form'>
                                    <Item>

                                        <Col xs={10} md={6} className='bg-white rounded poppins mt-3 pt-4 pb-4 ' data-aos="zoom-in">
                                            <h1 className='sacramento c-main f-small send'>
                                                Send Your Whises
                                        </h1>
                                            <form className="col-12 w-100 adnan">
                                                <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                                                <input ref={this.alamat} type='text' className="col-12 w-100 text-center" placeholder="Alamat" name='alamat' />
                                                <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                                                <Item>                             
                                                    <Col xs={6} onClick={() => {
                                                        this.setState({ hadir: true })
                                                    }
                                                    }>
                                                        <input id="option" type="radio" checked={hadir ? true : false} />
                                                        <label ><span><span></span></span>Hadir</label>
                                                        
                                                    </Col>
                                                    <Col xs={6} onClick={() => {
                                                        this.setState({ hadir: false })
                                                    }
                                                    }> 
                                                        <input id="option" type="radio" checked={hadir ? false : true} />
                                                        <label ><span><span></span></span>Tidak Hadir</label>
                                                       
                                                    </Col>     
                                                </Item>

                                                {!hadir ? false : (
                                                    <>  <Alert variant='dark'>
                                                        <Item>
                                                            <div onClick={() => {
                                                                this.setState({ sesi: 1 })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={sesi == 1 ? true : false} className='p-0' />
                                                                <label className='p-0 mb-0'><span><span></span></span>Sesi 1 (10.00 - 12.00 WIB)</label>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <div onClick={() => {
                                                                this.setState({ sesi: 2 })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={sesi == 2 ? true : false} className='p-0' />
                                                                <label className='p-0 mb-0'><span><span></span></span>Sesi 2 (12.30 - 14.00 WIB)</label>
                                                            </div>
                                                        </Item>
                                                       
                                                        </Alert>
                                                        <Alert variant='info'>
                                                            Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan
                                                        </Alert>
                                                    </>

                                                )}
                                                <Item>
                                                    <Col xs={12} className=''>
                                                        {
                                                            submitted == true ? (
                                                                <Alert variant='success'>
                                                                    Pesan anda sudah disampaikan
                                                                </Alert>) : (submitted === false ? (
                                                                    <Alert variant='danger'>
                                                                        {
                                                                            err.map(val => {
                                                                                return (
                                                                                    <li>{val}</li>
                                                                                )
                                                                            })
                                                                        }

                                                                    </Alert>
                                                                ) : false)
                                                        }

                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <div className='col-6 button rounded btn' onClick={() => this.handleSubmit()}> Kirim </div>
                                                </Item>
                                            </form>

                                        </Col>
                                    </Item>

                                    <Item>
                                        <h1 className='sacramento c-main f-small send pt-3 pb-3'>
                                            Denta & Fathan
                        </h1>
                                    </Item>
                                    <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                </Container>
                                
                            </div>
                            <Container fluid className='bg-green relative p-0' id='path'>
                                <img className='absolute img-fluid w-100 mx mr-0' src={path3} />
                            </Container>
                            <Container fluid style={{ backgroundColor: 'white' }}>
                                <Foot ig={logoig} dark />

                            </Container>
                        </>
                    ) : false}
                </div>
            </>
        )
    }
}

const Story = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        useCSS: true
    };
    const [count, setCount] = useState();
    let story = [
        [
            `Part 1: Per-“Kenal”-an Lagi`,
            (<p>&emsp;&emsp;(Denta) Pagi itu, tepatnya pukul 7.19 pagi pada bulan Mei, seorang laki-laki tiba-tiba menghubungiku melalui instagram, "Den, boleh minta no.hp mu?". Hazred Umar Fathan.. nama ini tidak asing. Aku, yang seorang introvert tentunya tidak langsung menjawab pertanyaannya. Beberapa kali aku membuka profil instagramnya. Entah apa yang terjadi, aku membalasnya. Jujur aku lupa kapan terakhir bertemu dengannya secara langsung. Satu minggu kemudian, ia mulai menghubungiku, "Halo Denta, ini Fathan". Setelah perbincangan panjang, dengan bodohnya aku bertanya, "Memangnya kita satu SMP?". Ia tertawa dan menanyakan beberapa kejadian menarik ketika SMP. Yang tentu saja aku sudah lupa. Namun cukup menyentuh hatiku karena ia ingat setiap detail-nya.
        <br/><br/>&emsp;&emsp;(Fathan) Berawal dari sering melihat aktifitas seorang wanita di social media, yang aku ingat adalah wanita yang cukup kukagumi ketika SMP, aku merasa ada hal yang menarik dari dirinya dan hatiku tak sanggup ingin mengetahui lebih banyak. Bagaimana cara ia melukiskan cerita, membuatku ingin menghampiri. Niatku memang bukan hanya ingin sekedar mampir, tapi lebih dari itu, untuk menetap dan tidak berpindah lagi. Dari niat, berjalan ke usaha. Hingga pada akhirnya aku mencari kontaknya. Langsung dari dirinya, tanpa basa basi, bersama doa yang mengiringi. Ia, Deninta Nur Iwana, wanita yang pernah mengancamku mengadukan kenakalanku kepada guru ketika SMP. Oh tidak, ia tidak mengadukanku.
        </p>)
        ], [
            `Part 2: Pertemuan Pertama`,
            (<p>&emsp;&emsp;(Fathan) 25 Juni 2020, aku telah berkomunikasi dengan “teman SMP”-ku selama 2 minggu, malam ini aku tidak bisa tidur. Ada yang mengganjal. Hatiku ingin menemuinya secara langsung untuk menggali lebih dalam tentang dirinya. Secepat kilat, jari ini mengetik, “Besok kita ketemu yuk”. 
            <br/><br/>
            &emsp;&emsp;(Denta) Depok, pukul 06.30 malam. Kata maaf adalah yang pertama kukeluarkan karena ketelatanku akibat macet yang melanda keadaan new normal kala itu. Ia berdiri menyambutku. Aku duduk di seberangnya, kikuk. Lalu ia mulai tersenyum. Kubalas dengan tawaan. Tetap saja, kikuk. Siapa juga yang membalas senyuman dengan tawaan, cuma orang aneh, pikirku sambil mengutuki diriku. Lalu percakapan mulai berjalan. Sambil curi-curi pandang, menatap mukanya ketika ia menunduk, lalu pura-pura memainkan handsanitizer ketika ia menangkapku basah tengah memandanginya. Percakapannya panjang dan seru, tak terasa waktu begitu cepat, sampai Papahku mengingatkanku untuk segera pulang karena kemalaman. Sungguh, aku baru menyadari sudah pukul 9 lewat. Tentu saja sebuah record bagiku, karna aku bukan anak malam yang suka pergi main selarut itu selama aku tinggal di Bogor.
            <br/><br/>
            &emsp;&emsp;(Fathan) Ternyata setelah tidak bertemu dari 10 tahun lamanya, terasa tak ada yang berbeda dari sosok Denta yang aku lihat di bangku putih biru dahulu. Ia masih Denta yang sama. Senyumnya, matanya, sungguh ia tidak berubah. Aku berusaha sekuat tenaga untuk tidak memperlihatkan kegugupanku. Aku pun bingung mengapa ia sering memainkan handsanitizer dan mengoles isinya ke tangannya setiap 5 menit sekali. Aku juga bingung harus bereaksi apa ketika Papahnya menelponnya untuk segera pulang. Aku seperti bukan diriku.
            </p>)
        ],
        [
            `Part 3: Perkenalan Keluarga`,
           (<p>
            &emsp;&emsp;(Denta) 26 Juli 2020, pagi yang cerah dan dingin di Kota Bogor. Pagi yang produktif juga bagiku. Seperti disiram air es se-ember, tubuhku mendadak kaku ketika melihat beberapa kalimat di pesan whatsapp. "Aku kaya kepikiran terus buru2 ajak mamah ayahku ke tempat kamu", "iyaa gatau ntah kenapa naluriku pengen ngelakuin itu", "Kamu di rumah?", "Lengkap ga keluarga kamu?", "Aku ajak mama ayahku ya".
            <br/>Dengan hitungan detik aku berlari ke kamar mamah papahku lalu berkata, “Mah, Pah. Keluarga Fathan mau main ke rumah 2 jam lagi”.

           <br/><br/>
           &emsp;&emsp;(Fathan) Pertemuan itu aku memberanikan diri untuk mengajukan cinta dengan membicarakannya kepada orangtuanya. Kenapa aku lakukan itu, karena aku tidak mau merasa hubungan ini hanya untuk canda gurau semata dan tidak punya akhir yang nyata. Karena itu aku pun berusaha meminta kepada orangtuaku untuk mendampingiku memantapkan hati dan niat untuk bisa hidup bersamanya. Pada mulanya aku merasa menikah itu hal yang cukup rumit. Namun ketika telah mengenalnya selama kurang lebih dua bulan, entah mengapa atas izin Allah, hatiku mantap dan yakin.
           
           </p>)
        ],
        [
            `Part 4: Akad Nikah`,
           (<p>
            &emsp;&emsp;(Denta) 25 Desember 2020, malam itu jantungku berdetak sangat cepat. Kusembunyikan dalam senyuman. Aku tidak bisa tidur. Malam terasa sangat panjang. Beberapa kali membasahi tenggorokan dengan air hangat, namun percuma, tidak membuat jantung ini lebih tenang. Aku tidak menyangka bahwa besok akan tiba hari yang kutunggu. Tidak menyangka, setelah drama per-kesehatan-an yang telah keluargaku dan Fathan lalui, prosesi akad nikah benar-benar akan terjadi besok. Air mata kesedihan telah berubah menjadi air mata kebahagiaan. Setelah banyak harapan yang terpatahkan, akhirnya kebahagiaan itu hadir.
           <br/><br/>
           &emsp;&emsp;(Fathan) 26 Desember 2020, pagi itu aku tidak tidur. Mengulang kata-kata ijab seperti kaset rusak. Antara takut dan lega. Antara percaya dan tidak percaya. Antara bahagia dan sesak yang tidak bisa digambarkan. Namun dari segala rasa yang bercampur, ada satu rasa yang sangat kuat; yaitu keyakinan. Aku yakin, sangat-sangat yakin. Yakin bahwa Denta adalah rumahku dan masa depanku.
           
           </p>)
        ]
    ]
    let plus= 'https://www.flaticon.com/svg/static/icons/svg/980/980385.svg'
    let minus= 'https://www.flaticon.com/svg/static/icons/svg/1721/1721949.svg'
    return (
        <Container fluid id="story" className="col-12 pt-5" style={{backgroundColor:'#171717'}}>
            <Row className="justify-content-center">
                <Col sm={8}>
                <div id="story1 row justify-content-center">
            <h1 className="judul c-main">
                Our Love Story
            </h1></div>
            
            <div>
                <Col xs={12} className="inject">
                                {story.map((v,i)=>{
                                    return(
                                    <>
                                    <Row className="px-2" 
                                    onClick={()=>{
                                        if(i==count){
                                            setCount(100)
                                        }else{
                                            setCount(i)
                                        }
                                    }}>
                                    <Col xs={10} className="my-auto">
                                    <h1 className="text-left mb-0 py-2">
                                    {v[0]}
                                        </h1>
                                    </Col>
                                <Col xs={2} className="my-2 text-right">
                                    <img src={i==count?minus:plus} className="ml-auto my-auto"/>
                                </Col>
                                </Row>
                                {
                                    count==i?(
                                        <Row className="px-3" style={{borderTop:'none'}}>
                                <p>
                                    {v[1]}
                                </p>
                                </Row>
                                    ):false
                                }
                                </>
                                )
                                })}
                </Col>
                            <Item>
                        </Item>
                </div>

                </Col>
            </Row>
             
        </Container>
    )
}

const Acara = (props) => {
    let { days, hours, minutes, seconds } = props
    return (
        <Container fluid className=' p-4' id='save' style={{backgroundColor:'#171717'}}>
            <h1 className='sacramento s-bold c-main' data-aos="fade-right">
                Save The Date
            </h1>
            <Item>
                <Col xs={10} md={6} className='p-3 rounded  s-bold c-main' data-aos="fade-left">
                    <Item>
                        <div className='item'>
                            <Item>
                                <div>
                                    {days}
                                </div>
                            </Item>
                            <Item>
                                <span>
                                    Days
                                </span>
                            </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                            <Item>
                                <div>
                                    {hours}
                                </div>
                            </Item>
                            <Item>
                                <span>
                                    Hours
                                    </span>
                            </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                            <Item>
                                <div >
                                    {minutes}
                                </div>
                            </Item>
                            <Item>
                                <span>
                                    Mins
                                        </span>
                            </Item>
                        </div>
                        <div className='dot' >:</div>
                        <div className='item'>
                            <Item>
                                <div>
                                    {seconds}
                                </div>
                            </Item>
                            <Item>
                                <span>
                                    Secs
                                </span>
                            </Item>
                        </div>
                    </Item>
                </Col>
            </Item>
            <Item>
                <Col xs={12} md={8} className='p-3 border-pink rounded' style={{borderColor:'white'}}>
                    <Item>
                        <Col xs={4} md={2} className='p-3'>
                            <img src={burung} className='img-fluid w-100' data-aos="fade-right" />
                        </Col>
                    </Item>
                    <h1 className='sacramento s-bold mt-3 c-main f-mid' data-aos="fade-left">
                        Akad Nikah
                    </h1>
                    <p className='f-small poppins mt-4 c-white' data-aos="fade-left">
                        <div className='s-bold '>
                            Sabtu, 26 Desember 2020
                                            </div>
                        <span className='m-2'>09.00 WIB- Selesai</span><br />
                        <b>Masjid Az-Zikra Sentul</b> <br />
                       

                         </p>
                    <h1 className='sacramento s-bold mt-3 c-main f-mid' data-aos="fade-left">
                        Resepsi
                    </h1>
                    <p className='f-small poppins mt-4 c-white' data-aos="fade-left">
                        <div className='s-bold '>
                            Sabtu, 06 Februari 2021
                                            </div>
                        <span className='m-2'>10.00 – 14.00 WIB dengan dibagi menjadi 2 sesi</span><br />
                        <b>Van Hoeis Bogor</b> <br />
                        Jl. Siliwangi No.39, RT.01/RW.03, Sukasari, Kec. Bogor Timur, Kota Bogor

                         </p>

                    
                    <Item>
                        <Col 
                            onClick={() => {
                                window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=MW51am0zZHFpMnZlamhrZzEwaWFpcWxmMjggYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')
                            }}
                            xs={8} md={4} className='btn poppins c-white f-small rounded p-1 mt-3 bg-main' data-aos="zoom-in">
                            Add to Google Calendar
                            </Col>
                    </Item>
                </Col>
            </Item>
            <Item>
                <Col xs={12} md={8} id="health">
                    
                    <div id="story">
                    <div id="story1">
                        
                      
                        <h1 className="judul pt-4 c-main">
                            Health Protocol
                    </h1>  </div>
                    </div>

                   
                        <p className="text-center c-white">
                            Sehubungan dengan pandemi COVID19, untuk memenuhi standard protocol kesehatan, kami meminta kepada para hadirin untuk memakai masker selama prosesi berlangsung. Menjaga jarak minimal 1 meter antara satu sama yang lain, dan mencuci tangan dengan sabun dan air mengalir atau menggunakan handsanitizer. Selama makan, makanlah dengan tidak bergerumul dan berjauhan.
                        </p>
                </Col>
            </Item>
        </Container >
    )


}

const Transport = (props) => {
    return (
        <>
            <Container fluid style={{backgroundColor:'#171717'}}>
                <Item>
                    <Col xs={12} sm={8}>
                    <div id="story">
                    <div id="story1">
                    <h1 className="judul pt-4" style={{color:'rgb(245, 205, 79)'}}>
                        Transportation Detail
                    </h1>
                    </div>
                   
                    <div className="box" id="save" style={{backgroundColor:'#171717'}}>
                        <p style={{color:'white',fontWeight:600}} className='text-center' >
                        Gedung Van Hoeis berada di sebelah RS Vania. Area parking berada di seberang gedung.
                        </p>
                        <Item>
                        <Col onClick={() => { window.open('https://www.google.com/maps/dir//Van+Hoeis+Bogor,+Jl.+Siliwangi+No.39,+RT.01%2FRW.03,+Sukasari,+Kec.+Bogor+Timur,+Kota+Bogor/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x2e69c557852e9415:0x959ef307ed89395c?sa=X&ved=2ahUKEwjQ9JSjpfDsAhVBWH0KHawbC38Q9RcwFHoECBIQBQ') }} xs={8} md={4} 
                        className='button poppins f-small rounded p-1 btn'  data-aos="zoom-in" style={{backgroundColor:'rgb(245, 205, 79)'}}>
                            Get Direction
                        </Col>
                        </Item>
                    </div>
                </div>
                    </Col>
                    </Item>
            </Container>
        </>
    )
}
const Attandance = () => {
    let sesi = ['Sesi 1 (10.00-12.00 WIB)',
'Sesi 2 (12.30 – 14.00 WIB)',]
    return (
        <>
            <Container fluid style={{backgroundColor:'#171717'}}>
               <Item>
        <Col xs={12} sm={8}>
        <div id="story">
                    <div id="story1">
                    <h1 className="judul pt-4" style={{color:'rgb(245, 205, 79)'}}>
                        Attendance Confirmation (RSVP)
                    </h1></div>
                    <div className="box w-100">
                        <p className=" text-center w-100">
                        Sehubungan dengan pandemic COVID19, kami membagi acara resepsi pernikahan menjadi 2 sesi:
                        
                        </p>
                        <Row className="justify-content-center  ">
                            {sesi.map(v=>{
                                return (
                                    <Alert variant="primary col-sm-5 mx-3">
                                        <p className="text-center mb-0 pt-0">{v}</p>
                                    </Alert>
                                )
                            })}
                        </Row>
                    </div>
                </div>
        </Col>
               </Item>
            </Container>
        </>
    )
}
const Galery =()=>{
    return (
        <Container fluid className="pb-4 px-5" style={{backgroundColor:'#171717'}}>
                <div id="story1">
                    <h1 className="judul pt-4" style={{fontFamily:"Sacramento, cursive;",color:'#F5CD4F'}}>
                        Galery
                    </h1>
                    <SliderA/>
                </div>
        </Container>
    )
}